<template>
    <section class="relative md:py-24 py-16" id="pricing">
        <div class="container relative">
            <div class="grid grid-cols-1 text-center">
                <h3 class="font-semibold text-2xl leading-normal mb-4">Easily Accessible</h3>
                <p class="text-slate-400 mx-auto">Evac one can be simply downloaded by ECO members or staff from either
                    App Store or Google Play.</p>
                <p class="text-slate-400 mx-auto">
                    EVAC One allows emergency response teams (ECO members) and staff to communicate via the app during
                    an
                    active or armed intrusion, when staff need to be silent, staff can communicate with each other or
                    directly to emergency services via sms.
                </p>
            </div>
            <div class="grid grid-cols-1 pb-6 mx-auto">
                <ul class="list-none text-slate-400 mx-auto mt-4">
                    <li class="mb-1 flex"></li>
                    <li class="mb-1 flex"><i
                            class="mdi mdi-check-circle-outline text-green-500 text-[20px] align-middle me-2"></i>App
                        Connected to Fire Alarm Systems</li>
                    <li class="mb-1 flex"><i
                            class="mdi mdi-check-circle-outline text-green-500 text-[20px] align-middle me-2"></i>Receive
                        specific emergency procedures with every alarm activation.</li>
                    <li class="mb-1 flex"><i
                            class="mdi mdi-check-circle-outline text-green-500 text-[20px] align-middle me-2"></i>View
                        assembly areas and current GPS location in real time in the facility.</li>
                    <li class="mb-1 flex"><i
                            class="mdi mdi-check-circle-outline text-green-500 text-[20px] align-middle me-2"></i>
                        Easy communication between emergency response teams and staff.</li>
                    <li class="mb-1 flex"><i
                            class="mdi mdi-check-circle-outline text-green-500 text-[20px] align-middle me-2"></i>Easily
                        contact 000</li>
                    <li class="mb-1 flex"><i
                            class="mdi mdi-check-circle-outline text-green-500 text-[20px] align-middle me-2"></i>Eco
                        members and staff can advise when they are safe or trapped in the building via phone call or
                        sms.
                    </li>
                </ul>
            </div>
        </div>
        <div class="container relative">
            <div class="grid">
                <div class="grid grid-cols-2">
                    <a href="https://play.google.com/store/apps/details?id=com.tawcs.onsite.app" target="_blank">
                        <img src="../assets/google-play-badge.png" style="width: 175px; float:right;" /></a>
                    <a href="https://apps.apple.com/au/app/tawcs-enterprise-on-site/id1637364665?platform=iphone"
                        class="ml-5" target="_blank">
                        <img src="../assets/apple-store-badge-.png" style="width: 175px;" /></a>
                </div>
            </div>
        </div>
    </section>
</template>