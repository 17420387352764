<template>
    <section class="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="contact">
        <div class="container relative">
            <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                <div class="lg:col-span-6 md:col-span-6">
                    <img src="../assets/images/contact.svg" alt="">
                </div>

                <div class="lg:col-span-6 md:col-span-6">
                    <div class="lg:ms-5">
                        <h6 class="text-blue-500 text-sm font-semibold uppercase mb-2">Get In Touch</h6>
                        <h3 class="font-semibold text-xl leading-normal mb-2">1300 607 663</h3>
                        <h3 class="font-semibold text-xl leading-normal mb-2">info@evacone.com.au</h3>
                    </div>
                </div>
            </div>
        </div><!--end container-->
    </section>
</template>