<template>
    <div>
        <section class="relative md:py-24 py-16 md:pt-0 pt-0 mt-8" id="services">
            <div class="container relative">
                <div class="grid grid-cols-1 justify-center">
                    <div class="relative z-1">
                        <div class="grid grid-cols-1 md:text-start text-center justify-center">
                            <div class="relative">
                                <img src="../assets/images/nfc.png" alt="" class="rounded-lg">
                            </div>
                        </div>
                        <div class="content md:mt-8">
                            <div class="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                <div class="col-span-12">
                                    <div class="grid grid-cols-1 items-center">
                                        <div class="mt-4">
                                            <div class="section-title text-md-start">
                                                <h6 class="text-white/70 text-sm font-semibold uppercase">QR Code and
                                                    NFC Technology </h6>
                                            </div>
                                        </div>

                                        <div class="mt-4">
                                            <div class="section-title text-md-start">
                                                <p class="text-white/70 mb-2">Evac One has the capility to
                                                    provide quick and simple access to Emergency Procedures to staff or
                                                    the public.

                                                    Staff or members of the public can simply Tap or Scan Evac One QR
                                                    Dics and receive crucial Emergency Information

                                                    Directly on their phones.</p>

                                                <p class="text-white/70">
                                                    This feature is helping commercial office buildings to manage the
                                                    new
                                                    hurdle of hot desking and hybrid (working from home) working
                                                    environments.

                                                    Gone are the days of Fire Warden’s being located permanently in one
                                                    area
                                                    of the building. Evac One Tap and Go QR Code dics being placed on
                                                    every
                                                    worksation solves this problem. On the commencement of a Fire Alarm
                                                    or
                                                    Emergency Incident and a Fire Warden is not present, staff simply
                                                    Tap
                                                    and Go or Scan the QR code and follow specific procedures for that
                                                    location and closest Fire Exits and Assembly Areas and there exact
                                                    GPS
                                                    location in the building.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--end row -->
            </div><!--end container-->
            <div class="absolute bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-gray-400 to-gray-500">
            </div>
        </section>
        <div :class="isActive ? 'fixed' : 'hidden'"
            class="bg-black/[0.9] top-0 left-0 bottom-0 w-[100%] h-[100%] z-999">
            <div class="h-[100%] flex items-center justify-center">
                <div class="relative inline-block">
                    <iframe src="https://www.youtube.com/embed/S_CGed6E610" width="700" height="500"
                        frameborder="0"></iframe>
                    <a class="mdi mdi-close text-white absolute top-1 right-1 text-xl" @click="toggle"></a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            isActive: false
        }

    },
    methods: {
        toggle() {
            if (!this.isActive) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        },
    },

}
</script>