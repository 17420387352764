<template>
    <section class="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="services">
        <div class="container relative">
            <div class="grid grid-cols-1 pb-6 text-center">
                <h3 class="font-semibold text-2xl leading-normal mb-4">Features</h3>

                <!-- <p class="text-slate-400 max-w-xl mx-auto">This is just a simple text made for this unique and awesome
                    template, you can replace it with any text.</p> -->
            </div><!--end grid-->

            <div class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 mt-6">
                <div v-for="item in servicesData" :key="item.id"
                    class="group rounded-md shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-fit">
                    <div
                        class="flex items-center justify-center size-14 -rotate-45 bg-gradient-to-r from-transparent to-blue-500/10 text-blue-500 text-center rounded-full group-hover:bg-blue-500/10 duration-500">
                        <i :data-feather="item.icon" class="size-6 rotate-45"></i>
                    </div>

                    <div class="content mt-6 relative z-1">
                        <a href="" class="title text-lg font-semibold hover:text-blue-500">{{ item.title }}</a>
                        <p class="text-slate-400 mt-3">{{ item.desc }}</p>

                        <div class="mt-6">
                            <!-- <a href="" class="text-blue-500">Read More <i
                                    class="mdi mdi-chevron-right align-middle"></i></a> -->
                        </div>
                    </div>

                    <div class="absolute bottom-0 -end-16">
                        <i :data-feather="item.icon"
                            class="size-48 text-blue-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500"></i>
                    </div>
                </div>
            </div><!--end grid-->
        </div><!--end container-->
    </section>
</template>

<script>
import feather from 'feather-icons'
export default {
    data() {
        return {
            servicesData: [
                {
                    id: 1,
                    icon: 'trending-up',
                    title: 'Mobile App',
                    desc: 'EVAC One is a fully integrated emergency response training and communication software and mobile app.'
                },
                {
                    id: 2,
                    icon: 'list',
                    title: 'Procedures',
                    desc: 'EVAC One compliments a building or precincts current emergency response procedures and training management plan.'
                },
                {
                    id: 3,
                    icon: 'phone',
                    title: 'Communication',
                    desc: 'EVAC One allow for emergency response teams to Communicate and manage effectively during an emergency event.'
                },
                {
                    id: 4,
                    icon: 'command',
                    title: 'Fire Indicator Panel (FIP) Intergration',
                    desc: 'Evac One can be intergrated into a Building Early Warning  system (Fire Alarm). When a fire alarm occurs, EVAC One mobile app automatcially activates all user mobile phones that have joined the building or precinct emergency group.'
                },
                {
                    id: 5,
                    icon: 'compass',
                    title: 'Location',
                    desc: 'This feature enables clear site-specific emergency procedures to be relayed when they most need it, and will display exact GPS location of assembly areas and where staff are.'
                },
                {
                    id: 6,
                    icon: 'camera',
                    title: 'Training',
                    desc: 'EVAC One also makes mandatory fire warden retention training easy via the app. Emergency response teams (ECO members) and staff can complete their training at any time and at their own pace.'
                }
            ]
        }
    },

    mounted() {
        feather.replace();
    }

}
</script>