<template>
    <div>
        <!-- <Navbar /> -->
        <NavLight />
        <section class="swiper-slider-hero relative block h-screen" id="home">
            <div class="swiper-container absolute end-0 top-0 w-full h-full">
                <swiper class="swiper-wrapper" :modules="modules" :autoplay="true">
                    <swiper-slide class="swiper-slide flex items-center overflow-hidden">
                        <div class="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image flex items-center bg-center"
                            :style="{ 'background-image': 'url(' + require('../assets/images/bg/bg4.jpg') + ')' }">
                            <div class="absolute inset-0 bg-black/70"></div>
                            <div class="container relative">
                                <div class="grid grid-cols-1">
                                    <div class="text-start">
                                        <h1
                                            class="font-bold lg:leading-normal leading-normal tracking-wide text-2xl lg:text-3xl capitalize text-white mb-5">
                                            SETTING THE BENCHMARK <br> IN EMERGENCY MANAGEMENT</h1>
                                        <p class="text-white/70 text-lg max-w-xl">EVAC One is a fully integrated
                                            emergency
                                            response training and communication software and mobile app.</p>

                                        <div class="mt-6">
                                            <a href="#about"
                                                class="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-blue-500 text-white">See
                                                More</a>
                                        </div>
                                    </div>
                                </div><!--end grid-->
                            </div><!--end container-->
                        </div><!-- end slide-inner -->
                    </swiper-slide> <!-- end swiper-slide -->

                </swiper>
                <!-- end swiper-wrapper -->


            </div><!--end container-->
        </section>
        <!-- <section class="relative overflow-hidden md:py-48 py-40 bg-blue-500/5 dark:bg-blue-500/20" id="home">
            <div class="container relative mt-8">
                <div class="grid md:grid-cols-2 grid-cols-1 gap-6 items-center">
                    <div>
                        <h1 class="font-semibold lg:leading-normal leading-normal tracking-wide text-2xl lg:text-3xl mb-5">
                            SETTING THE BENCHMARK IN EMERGENCY MANAGEMENT</h1>
                        <p class="text-slate-400 text-lg max-w-xl">EVAC One is a fully integrated emergency response
                            training and communication software and mobile app.</p>

                        <div class="mt-6">
                            <a href=""
                                class="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-blue-500 text-white">Contact
                                Us <i class="mdi mdi-chevron-right ms-1"></i></a>
                        </div>
                    </div>

                    <div class="lg:ms-8">
                        <div class="relative">
                            <img src="../assets/images/design-team.svg" class="relative top-16" alt="">
                            <div
                                class="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tl to-blue-500/20 via-blue-500/70 from-blue-500 bottom-1/2 translate-y-1/2 md:start-0 start-1/2 ltr:md:translate-x-0 ltr:-translate-x-1/2 rtl:md:translate-x-0 rtl:translate-x-1/2 -z-1 shadow-md shadow-blue-500/10 rounded-full">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <About />
        <Features />
        <Cta />
        <!-- <Client /> -->
        <KeyFeatures></KeyFeatures>
        <NFC></NFC>
        <!-- <Pricing /> -->
        <!-- <Blogs /> -->
        <GetInTouch />
    </div>
</template>

<script>


import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import NavLight from '@/components/NavLight.vue'
import Navbar from '@/components/Navbar.vue'
import About from '@/components/About.vue'
import Features from '@/components/Features.vue'
import AgencyTab from '@/components/AgencyTab.vue'
import Cta from '@/components/Cta.vue'
import NFC from '@/components/NFC.vue'
import Client from '@/components/Client.vue'
import Pricing from '@/components/Pricing.vue'
import Blogs from '@/components/Blogs.vue'
import GetInTouch from '@/components/GetInTouch.vue'
import KeyFeatures from '@/components/KeyFeatures.vue'

export default {
    components: {
        Navbar,
        NavLight,
        About,
        Features,
        AgencyTab,
        Cta,
        NFC,
        Client,
        Pricing,
        Blogs,
        GetInTouch,
        KeyFeatures,
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, Autoplay],
        };
    },
}
</script>